export function extractBrowserVersion(): string | null {
  const userAgent = navigator.userAgent;
  const versionRegex = new RegExp(`${browserName()}/[\\d.]+`);
  const match = userAgent.match(versionRegex);
  return match ? match[0] : null;
}

export function browserName() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge';
  } else {
    browserName = 'No browser detection';
  }

  return browserName;
}

const getDeviceFromUserAgent = () => {
  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  if (/Android/i.test(userAgent)) return 'Android';
  if (/webOS/i.test(userAgent)) return 'webOS';
  if (/iPhone/i.test(userAgent)) return 'iPhone';
  if (/iPad/i.test(userAgent)) return 'iPad';
  if (/iPod/i.test(userAgent)) return 'iPod';
  if (/BlackBerry/i.test(userAgent)) return 'BlackBerry';
  if (/IEMobile/i.test(userAgent)) return 'Windows Phone';
  if (/Opera Mini/i.test(userAgent)) return 'Opera Mini';
  return 'Unknown Device';
};

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const isMobileScreen = () => window.innerWidth <= 768;
  const isMobileTouch = () =>
    'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const testDevice = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  return isMobileScreen() || isMobileTouch() || testDevice();
};

export const getDeviceType = () => {
  if (isMobileDevice()) {
    return getDeviceFromUserAgent();
  }
  return 'Desktop';
};

export function getCurrentDateTime() {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months start at 0 in JavaScript
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');

  const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`; // ISO 8601 UTC format
  return timestamp; // Returns current date and time in UTC "YYYY-MM-DDTHH:MM:SSZ" format
}

export function isExpired(
  dateTimeStr: string,
  durationInSeconds: number
): boolean {
  // Parse the date-time string to get the date object
  const dateTime = new Date(dateTimeStr);

  // Calculate the expiry time
  const expiryTime = new Date(dateTime.getTime() + durationInSeconds * 1000);

  // Get the current time
  const currentTime = new Date();

  // Check if the current time is past the expiry time
  return currentTime > expiryTime;
}
