import { lazy, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

const NavBarWithSearch = lazy(
  () => import('../components/navigation/searchbar/NavSearchBar')
);

const HomeView = lazy(() => import('../views/home/HomeView'));
const CategoryView = lazy(() => import('../views/category/CategoryView'));
const DetailView = lazy(() => import('../views/detail/DetailView'));
const VariationView = lazy(() => import('../views/variation/VariationView'));
const SearchView = lazy(() => import('../views/search/SearchView'));
const Terms = lazy(() => import('../views/static/terms/Terms'));
const Policy = lazy(() => import('../views/static/policy/Policy'));
const About = lazy(() => import('../views/static/about/About'));
const Help = lazy(() => import('../views/static/help/Help'));

function RouterComponent() {
  // TODO: Removing useLocation() causes a bug in which the navigation bar appears on the home page.
  // Fix this bug later and remove this statement.
  const location = useLocation();
  let NavBarComponent = null;
  const pathName = window.location.pathname;

  if (pathName !== '/login' && pathName !== '/') {
    NavBarComponent = <NavBarWithSearch />;
  }

  function renderRoutes() {
    return (
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/category/:category" element={<CategoryView />} />
          <Route path="/move/:move" element={<DetailView />} />
          <Route path="/variation/:variation" element={<VariationView />} />
          <Route path="/search" element={<SearchView />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/about" element={<About />} />
          <Route path="/help" element={<Help />} />
        </Routes>
      </Suspense>
    );
  }

  return (
    <>
      <Suspense fallback={<div></div>}>
        <div>{NavBarComponent}</div>
      </Suspense>
      {renderRoutes()}
    </>
  );
}

export default RouterComponent;
