import { BrowserRouter } from 'react-router-dom';
import RouterComponent from './router/RouterComponent';
import { isMobileDevice } from './utils/metadata';

function App() {
  const state = isMobileDevice().toString();
  localStorage.setItem('isMobile', state);

  return (
    <BrowserRouter>
      <RouterComponent />
    </BrowserRouter>
  );
}

export default App;
